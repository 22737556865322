const faqs = [
  {
    question: "1. ¿Quienes pueden utilizar la asistencia al viajero VISA? ",
    answer: (
      <div>
        <p> La cobertura de asistencia de viaje de VISA abarca al titular de la tarjeta y a su grupo familiar directo, que incluye a su cónyuge o pareja y a sus hijos menores de 26 años, además de los adicionales. Por lo general, esta cobertura se aplica a aquellos que poseen Visa Platinum o Signature. Por el momento no se ofrece el servicio de asistencia al viajero para la tarjeta VISA Classic y VISA Gold. <br/> <br/>

Además, no sólo es relevante el tipo de tarjeta VISA que poseas, sino también el banco emisor de la tarjeta. Durante los últimos meses, muchos bancos han modificado las condiciones del servicio de asistencia al viajero, ya sea eliminando la cobertura por completo o limitándola a casos específicos.<br/> <br/>

Es importante destacar que solo tendrán el beneficio de asistencia al viajero aquellos que hayan comprado el pasaje utilizando la tarjeta VISA que incluye dicha cobertura. Si no se utilizó esa tarjeta para la compra del pasaje, no se podrá acceder a la asistencia al viajero proporcionada por VISA.

</p>
      </div>
    ),
  },
  {
    question: "2. ¿Cuántos días me cubre? ¿Qué pasa si quiero quedarme más días?",
    answer: (
      <div>
        <p> La cobertura de viaje varía según el tipo de tarjeta Visa que poseas. Para las tarjetas Visa Platinum o Signature, la cobertura actualmente se extiende a 120 días consecutivos por viaje, mientras que en otras tarjetas solo te cubre por 30 días. <br/> <br/>


Resulta más económico y beneficioso en cobertura de seguro de viaje contratar otro seguro exterior a la asistencia al viajero VISA. De ser así, evitarías contratar por un costo adicional y significativo una extensión de tu seguro. 


 </p> 

      </div>
    ),
  },
  {
    question: "3. ¿Qué cubre la asistencia al viajero VISA?",
    answer: (
      <div>
        <p>
        Si la tarjeta de tu banco brinda este servicio, la cobertura puede abarcar una amplia gama de beneficios, como asistencia médica durante el viaje, reembolso de gastos médicos, localización de equipaje en caso de extravío, transporte o repatriación sanitaria en situaciones de emergencia, asesoramiento legal, atención odontológica de emergencia, y una variedad de otros beneficios. Esto debes chequearlo antes de contratar el seguro de viaje, debido a que no todas las tarjetas lo ofrecen. 
 
        </p> 
      </div>
    ),
  },

];

export default faqs;
