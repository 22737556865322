import React, { useState, useEffect, useContext } from "react";
import Hero from "../hero/Hero";

import Testimonials from "./../testimonials/Testimonials";

import FaqComponent from "../faq/index.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import { StoreContext } from "../../context/StoreContext.js";
import { types } from "../../context/StoreReducer";
import { QuoterComponent } from 'quoter365';
import { quoter_config } from '../../config/app.config.js';

const HomeComponent = () => {
  const [store, dispatch] = useContext(StoreContext);
  const banner_visibility = useMediaQuery("(min-width: 640px)");

  const getData = () => {};

  useEffect(() => {
    if (store.geo) {
      localStorage.setItem("country_name", store.geo.country_name);
      localStorage.setItem("currency_symbol", store.geo.currency_symbol);
      localStorage.setItem("entity_id", store.geo.entity);
      localStorage.setItem("seller_id", store.geo.seller);
      localStorage.setItem("country_code", store.geo.country);
      localStorage.setItem("country_id", store.geo.country_id);
      localStorage.setItem("header_whatsapp", store.geo.header_whatsapp);
      localStorage.setItem("locale", store.geo.locale);
    }
  }, [store.geo]);

  useEffect(() => {
    if (
      localStorage.getItem("country_name") === null ||
      localStorage.getItem("currency_symbol") === null ||
      localStorage.getItem("entity_id") === null ||
      localStorage.getItem("seller_id") === null ||
      localStorage.getItem("country_code") === null ||
      localStorage.getItem("country_id") === null ||
      localStorage.getItem("header_whatsapp") === null ||
      localStorage.getItem("locale") === null
    ) {
      try {
        let url = window.location.href;
        const country_code = url.split("/")[3];
        fetch(
          `https://app.assist-365.com/api/geolocation?flag=true&country_code=${country_code}`
        )
          .then((response) => response.json())
          .then((response) => {
            dispatch({
              type: types.setGeo,
              payload: response,
            });
          })
          .catch((err) => console.error(err));
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  return (
    <>
      <Hero />
      <div className='w-full mx-auto max-w-[358px] sm:max-w-[633px] xl:max-w-[960px] mt-12 mb-12 px-4 '>
        <h2 className="font-bold text-blue-primary text-center font-titillium text-3xl sm:text-4xl xl:text-6xl mb-12 mt-6 px-4">
        ¿Qué incluye la asistencia al viajero VISA?
        </h2>
         <p className='px-4'>  Todo lo que necesitás saber sobre la asistencia al viajero VISA y detalles a tener en cuenta antes de contratar este servicio. </p> 
       
      </div>

      <div className="w-full mx-auto max-w-[358px] sm:max-w-[633px] xl:max-w-[1210px] px-4">
        <FaqComponent />
      </div>

      <div className='w-full mx-auto max-w-[358px] sm:max-w-[633px] xl:max-w-[960px] mt-12 mb-12 px-4 '>
        <h2 className="font-bold text-blue-primary text-center font-titillium text-3xl sm:text-4xl xl:text-6xl mb-12 mt-6 px-4">
        ¿Qué diferencia ASSIST 365 de un seguro de viaje VISA?
        </h2>
        <p className='px-4'> 

Es importante tener en cuenta que los bancos tienen políticas cambiantes en cuanto a lo que cubren y lo que no en relación a la asistencia al viajero. Estas condiciones varían según el tipo de tarjeta que tengas. Sin embargo, en su mayoría, los bancos no cubren las preexistencias médicas, y es posible que no dispongan de un número de WhatsApp para realizar consultas o solicitar asistencia.<br/> <br/>Las tarjetas Gold ya no ofrecen cobertura de asistencia al viajero y en el caso de otras tarjetas, es necesario haber adquirido el pasaje utilizando esa tarjeta para que se aplique la cobertura. Además, algunas tarjetas pueden tener restricciones en cuanto a la edad de los hijos cubiertos, excluyendo a aquellos que sean mayores de 18 o 21 años.<br/> <br/>Los límites de cobertura de las tarjetas suelen ser inferiores. Mientras que en ASSIST 365 llegamos a cubrir a partir de un monto de $200.000, el límite más alto para la cobertura de asistencia al viajero ofrecido por las tarjetas suele ser desde $30.000 hasta $150.000, dependiendo de la tarjeta y banco. Con ASSIST 365 no tendrás que depender de lo que la tarjeta te brinde, sino lo que contratás. <br/> <br/>En algunos casos algunas tarjetas sólo cubren por accidentes o enfermedades, en ASSIST 365 podés contar para todo lo que necesites, las 24 horas del día, con atención personalizada para darte el mejor servicio. <br/> <br/>Por lo que recomendamos siempre informarse acerca de estos puntos y revisar detalladamente los términos y condiciones de la cobertura de asistencia al viajero ofrecida por cada tarjeta y banco en particular.
</p> 
       
      </div>

      <div className='w-full mx-auto max-w-[358px] sm:max-w-[633px] xl:max-w-[960px] mt-12 mb-12 px-4 '>
        <h2 className="font-bold text-blue-primary text-center font-titillium text-3xl sm:text-4xl xl:text-6xl mb-12 mt-6">
        Beneficios de viajar  con asistencia al viajero
        </h2>
      
        <div className="video-container max-w-[793px] mx-auto h-full px-4">
          <iframe
            width="100%"
         
            src="https://www.youtube.com/embed/o0_f9viIv9k?si=G8vffZYvrLUusokI"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen

            className='xl:h-[434px] md:h-[331px] h-[186px]'
          ></iframe>
        </div>
      </div>


      {/* Quoter */}
      <div className=' w-full flex flex-col items-center bg-quoter-landscape '>

      <div className="w-full mx-auto max-w-[358px] sm:max-w-[633px] xl:max-w-[960px] px-4">
        <h2 className="font-bold text-white md:text-blue-primary text-center font-titillium text-3xl sm:text-4xl xl:text-6xl mb-12 mt-6">
        Cotiza tu seguro de viaje 
        </h2>
      </div>
   
              <div className="quoter-wrapper sm:bg-[rgba(178,205,255,0.68)] rounded-[20px] sm:px-[14px] sm:py-[20px]
              max-w-[592px]  mb-20  mx-auto ">
                <div className="text-base md:text-xl font-semibold font-titillium text-white mb-[10px] md:mb-5 pl-1">
                Cotiza ahora tu asistencia al viajero Assist 365
                </div>
                <QuoterComponent
                  className="relative "
                  view_mode={"landscape"}
                  config={quoter_config}
                  quoterData={getData}
                />
              </div>
            </div>

            <div className="w-full mx-auto max-w-[358px] sm:max-w-[633px] xl:max-w-[1210px] px-4">
        <Testimonials />
      </div>

      

    </>
  );
};

export default HomeComponent;